import React from "react"
import "./VirtualMachines.scss"
import SideNav from "./SideNav"
import BgImg from "../images/bg-image.svg"
import Highlight from '../images/vm-icon.svg'
import PropTypes from "prop-types"
import { FormattedHTMLMessage, FormattedMessage, Link } from "gatsby-plugin-intl"

const VirtualMachines = ({ showBtn, showSideNav }) => {

  const addToCart = () => {
    let cart = parseInt(localStorage.getItem("cart"));
    cart++;
    localStorage.setItem("cart", cart.toString());
    let cartItems = JSON.parse(localStorage.getItem("cartItems"));

    const newItem = {
      'name': 'test vm',
      'description': 'test vm desc',
      'price': 199.00,
      'quantity': 1,
      'type': 'vm'
    }
    if ( cartItems.length > 0) {
      const hasItem = cartItems.find( (item) => {
        if (item['name'] === newItem['name']) {
          item['quantity']++;
          return true;
        }
        return false;
      });

      if(!hasItem) {
        cartItems.push(newItem);
      }
    } else {
      cartItems.push(newItem);
    }

    cartItems = JSON.stringify(cartItems);
    localStorage.setItem("cartItems", cartItems);
    const setStorage = new Event('setStorage');
    window.dispatchEvent(setStorage)
  }

  const btn = () => {
    if (showBtn) {
      return (
        <div className="vm-contact">
          <Link to="/virtual-machines/" className="vbtn vbtn--virtual"><FormattedMessage id="buttons.learn_more" /></Link>
        </div>
      )
    }
  }

  const sideNav = () => {
    if ( showSideNav ) {
      return <SideNav active={1} section="vm" />;
    }
  }

  return (
    <section className="vm-section side-nav--grey main-product-section" header-mod="header-light" btn-mod="--virtual" cookie="normal">
      {sideNav()}
      <BgImg className="section__bg-image"/>
      <BgImg className="section__bg-image"/>
      <BgImg className="section__bg-image"/>
      <div className="grid-wrapper">
        <div className="section__highlight-container">
          <Highlight className="section__highlight-image" />
          <Highlight className="section__highlight-image" />
          <Highlight className="section__highlight-image" />
        </div>
        <div className="section-header-container">
          <FormattedHTMLMessage id="vms.title" />
        </div>
        <div className="product-characteristics-container">
          <ul className="product-characteristics">
            <li><FormattedMessage id="vms.product_highlights.high1" /> <span></span></li>
            <li><FormattedMessage id="vms.product_highlights.high2" /> <span></span></li>
            <li><FormattedMessage id="vms.product_highlights.high3" /> <span></span></li>
          </ul>
        </div>
        <div className="product-pricing">
          <h4><FormattedMessage id="vms.starting_at" /></h4>
          <h3><FormattedMessage id="vms.starting_price" /></h3>
        </div>
        {btn()}
      </div>
    </section>
  )
}

VirtualMachines.propTypes = {
  showBtn: PropTypes.bool,
  showSideNav: PropTypes.bool
}

export default VirtualMachines
