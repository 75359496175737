import React from "react"
import "./Servers.scss"
import SideNav from "./SideNav"
import BgImg from '../images/bg-image.svg'
import Highlight from '../images/server-highlight-img.svg'
import PropTypes from "prop-types"
import { FormattedHTMLMessage, FormattedMessage, Link } from "gatsby-plugin-intl"

const Servers = ({ showBtn, showSideNav }) => {

  const btn = () => {
    if ( showBtn ) {
      return (
        <div className="servers-contact">
          <Link to="/servers/" className="vbtn vbtn--gradient"><FormattedMessage id="buttons.learn_more" /></Link>
        </div>
      )
    }
  }

  const sideNav = () => {
    if ( showSideNav ) {
      return <SideNav type="light-blue" active={0}  section="servers"/>;
    }
  }
  return (
    <section className="servers-section side-nav--light-blue main-product-section" header-mod="header-normal" btn-mod="--gradient" cookie="normal">
      {sideNav()}
      <BgImg className="section__bg-image"/>
      <div className="grid-wrapper">
        <div className="section__highlight-container">
          <Highlight className="section__highlight-image" />
        </div>
        <div className="section-header-container">
          <FormattedHTMLMessage id="servers.title" />
        </div>
        <div className="product-characteristics-container">
          <ul className="product-characteristics">
            <li><FormattedMessage id="servers.product_highlights.high1" /></li>
            <li><FormattedMessage id="servers.product_highlights.high2" /></li>
            <li className="break-flex"></li>
            <li><FormattedMessage id="servers.product_highlights.high3" /></li>
          </ul>
        </div>
        <div className="product-pricing">
          <h4><FormattedMessage id="servers.starting_at" /></h4>
          <h3><FormattedMessage id="servers.starting_price" /></h3>
        </div>
        {btn()}

      </div>
    </section>
  )
}

Servers.propTypes = {
  showBtn: PropTypes.bool,
  showSideNav: PropTypes.bool
}

export default Servers
