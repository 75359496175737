import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import VirtualMachines from "../components/VirtualMachines"
import Servers from "../components/Servers"
import Containers from "../components/Containers"
import Cloud from "../components/Cloud"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Servers showBtn={true} showSideNav={true} />
    <VirtualMachines showBtn={true} showSideNav={true} />
    <Containers showBtn={true} showSideNav={true} />
    <Cloud showBtn={true} showSideNav={true} />
  </Layout>
)

export default IndexPage
