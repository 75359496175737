import React from "react"
import { Link } from "gatsby"
import "./Cloud.scss"
import SideNav from "./SideNav"
import BgImg from "../images/bg-image.svg"
import BgClouds from "../images/clouds.svg"
import Highlight from "../images/cloud-big.svg"
import PropTypes from "prop-types"
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"

const Cloud = ({ showBtn, showSideNav }) => {
  const btn = () => {
    if (showBtn) {
      return (
        <div className="cloud-contact">
          <Link to="/cloud/" className="vbtn vbtn--blue"><FormattedMessage id="buttons.create_account" /></Link>
        </div>
      )
    }
  }

  const sideNav = () => {
    if ( showSideNav ) {
      return <SideNav active={3} section="cloud" />;
    }
  }

  return (
    <section className="cloud-section side-nav--grey main-product-section" header-mod="header-cloud" btn-mod="--blue" cookie="blue">
      {sideNav()}
      <BgImg className="section__bg-image"/>
      <div className="grid-wrapper">
        <div className="bg-clouds__container">
          <BgClouds className="bg-clouds" />
        </div>
        <div className="section__highlight-container">
          <Highlight className="section__highlight-image" />
        </div>
        <div className="section-header-container">
          <FormattedHTMLMessage id="hosting.title" />
        </div>
        <div className="product-characteristics-container">
          <ul className="product-characteristics">
            <li><FormattedMessage id="hosting.product_highlights.high1" /></li>
            <li><FormattedMessage id="hosting.product_highlights.high2" /></li>
            <li><FormattedMessage id="hosting.product_highlights.high3" /></li>
          </ul>
        </div>
        <div className="product-pricing">
          <h4><FormattedMessage id="hosting.starting_at" /></h4>
          <h3><FormattedMessage id="hosting.starting_price" /></h3>
        </div>
        {btn()}
      </div>
    </section>
  )
}

Cloud.propTypes = {
  showBtn: PropTypes.bool,
  showSideNav: PropTypes.bool
}

export default Cloud
